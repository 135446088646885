<template>
  <div
    class="flex items-center justify-center px-8 h-28 w-full bg-slate-100 text-slate-700 dark:bg-slate-500 dark:text-slate-75"
  >
    <fluent-icon icon="document-error" size="32" />
    <p class="mb-0 text-slate-700 dark:text-slate-75">
      {{ $t('COMPONENTS.FILE_BUBBLE.INSTAGRAM_STORY_UNAVAILABLE') }}
    </p>
  </div>
</template>

<script>
export default {};
</script>
